.custom-controller {
  input[type="range"] {
    -webkit-appearance: none;
    overflow: hidden;
    height: 4px;
    width: 100%;
    cursor: pointer;
    border-radius: 0;
  }

  ::-webkit-slider-runnable-track {
    background: white;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0px;
    height: 0px;
    background: #fff;
    box-shadow: -2000px 0 0 2000px var(--huudle-color-f45b69);
  }

  ::-moz-range-track {
    height: 4px;
  }

  ::-moz-range-thumb {
    background: transparent;
    height: 0px !important;
    width: 0px !important;
    border-radius: 0 !important;
  }

  ::-ms-fill-lower {
    background: red;
  }

  ::-ms-thumb {
    background: #fff;
    height: 0px !important;
    width: 0px !important;
  }

  ::-ms-ticks-after {
    display: none;
  }

  ::-ms-ticks-before {
    display: none;
  }

  ::-ms-track {
    background: white;
    color: transparent;
    border: none;
  }

  ::-ms-tooltip {
    display: none;
  }
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}
