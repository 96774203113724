:root {
  /* Colors: */
  --huudle-color-000000: #000000;
  --huudle-color-063a6d: #063a6d;
  --huudle-color-06d6a0: #00a040;
  --huudle-color-102048: #102048;
  --huudle-color-13ad2d: #13ad2d;
  --huudle-color-27c940: #27c940;
  --huudle-color-2e2836: #2e2836;
  --huudle-color-332f63: #332f63;
  --huudle-color-3b3e43: #3b3e43;
  --huudle-color-413f63: #413f63;
  --huudle-color-504d76: #504d76;
  --huudle-color-504d79: #504d79;
  --huudle-color-5b4dad: #5b4dad;
  --huudle-color-5c55b0: #5c55b0;
  --huudle-color-5deba9: #5deba9;
  --huudle-color-655dc6: #655dc6;
  --huudle-color-737373: #737373;
  --huudle-color-747474: #747474;
  --huudle-color-767588: #767588;
  --huudle-color-7d69ed: #7d69ed;
  --huudle-color-808080: #808080;
  --huudle-color-86a7ff: #86a7ff;
  --huudle-color-885803: #885803;
  --huudle-color-8a603c: #8a603c;
  --huudle-color-a4a4bc: #a4a4bc;
  --huudle-color-aaaaaa: #aaaaaa;
  --huudle-color-adadad: #adadad;
  --huudle-color-adc3ff: #adc3ff;
  --huudle-color-bee9de: "#bee9de";
  --huudle-color-bfbfbf: #bfbfbf;
  --huudle-color-c2986b: #c2986b;
  --huudle-color-c6cff5: #c6cff5;
  --huudle-color-d0d0d0: #d0d0d0;
  --huudle-color-d3dfff: #d3dfff;
  --huudle-color-dad9da: #dad9da;
  --huudle-color-dd8e00: #dd8e00;
  --huudle-color-e0a218: #e0a218;
  --huudle-color-e0e1ec: #e0e1ec;
  --huudle-color-e14842: #e14842;
  --huudle-color-e3e3e3: #e3e3e3;
  --huudle-color-e9f5fe: #e9f5fe;
  --huudle-color-edecfc: #edecfc;
  --huudle-color-ef86ce: #ef86ce;
  --huudle-color-f3f5fa: #f3f5fa;
  --huudle-color-f45b69: #dd0505;
  --huudle-color-f4f4f5: #f4f4f5;
  --huudle-color-f5f6f8: #f5f6f8;
  --huudle-color-f7f7f7: #f7f7f7;
  --huudle-color-f8f3e6: #f8f3e6;
  --huudle-color-fce7e7: #fce7e7;
  --huudle-color-fcf3ab: #fcf3ab;
  --huudle-color-fda428: #fda428;
  --huudle-color-ff5f57: #ff5f57;
  --huudle-color-ffa400: #ffa400;
  --huudle-color-ffbd2e: #ffbd2e;
  --huudle-color-ffffff: #ffffff;

  /* Font/text values */
  --huudle-font-style-normal: normal;
  --huudle-font-style-oblique: oblique;
  --huudle-font-weight-medium: medium;
  --huudle-font-weight-normal: normal;
  --huudle-font-weight-bold: bold;
  --huudle-font-size-8: 8px;
  --huudle-font-size-10: 10px;
  --huudle-font-size-12: 12px;
  --huudle-font-size-14: 14px;
  --huudle-font-size-16: 16px;
  --huudle-font-size-18: 18px;
  --huudle-font-size-20: 20px;
  --huudle-font-size-24: 24px;
  --huudle-font-size-28: 28px;
  --huudle-font-size-36: 36px;
  --huudle-font-size-40: 40px;
  --huudle-font-size-45: 45px;
  --huudle-character-spacing-0: 0px;
  --huudle-character-spacing-0-13: 0.13px;
  --huudle-character-spacing-0-3: 0.3px;
  --huudle-character-spacing-0-35: 0.35px;
  --huudle-character-spacing-0-15: 0.15px;
  --huudle-character-spacing-0-2: 0.2px;
  --huudle-line-spacing-10: 10px;
  --huudle-line-spacing-12: 12px;
  --huudle-line-spacing-14: 14px;
  --huudle-line-spacing-15: 15px;
  --huudle-line-spacing-16: 16px;
  --huudle-line-spacing-17: 17px;
  --huudle-line-spacing-18: 18px;
  --huudle-line-spacing-19: 19px;
  --huudle-line-spacing-20: 20px;
  --huudle-line-spacing-22: 22px;
  --huudle-line-spacing-24: 24px;
  --huudle-line-spacing-30: 30px;
  --huudle-line-spacing-35: 35px;
  --huudle-line-spacing-45: 45px;
  --huudle-line-spacing-50: 50px;
  --huudle-line-spacing-53: 53px;
  --huudle-text-transform-lowercase: lowercase;
  --huudle-text-transform-titlecase: titlecase;
  --huudle-text-transform-uppercase: uppercase;
  --huudle-decoration-underline: underline;
}

/* Character Styles */
.huudle-typography-8-10 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-8);
  line-height: var(--huudle-line-spacing-10);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-8-10-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-8);
  line-height: var(--huudle-line-spacing-10);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-8-20 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-8);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-8-20-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-8);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-12 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-12);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-12-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-12);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-12-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-12);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-16 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-16);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-16-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-16);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-16-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-16);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-19-medium-normal-0-13 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-19);
  letter-spacing: var(--huudle-character-spacing-0-13);
}

.huudle-typography-10-20-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-20-medium-lowercase {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
  text-transform: var(--huudle-text-transform-lowercase);
}

.huudle-typography-10-20-medium-oblique {
  font-style: var(--huudle-font-style-oblique);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-20-medium-normal-0-13 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0-13);
}

.huudle-typography-10-20-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-24-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-24);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-10-24-bold-uppercase {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-10);
  line-height: var(--huudle-line-spacing-24);
  letter-spacing: var(--huudle-character-spacing-0);
  text-transform: var(--huudle-text-transform-uppercase);
}

.huudle-typography-12-14 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-14);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-14-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-14);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-14-medium-underline {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-14);
  letter-spacing: var(--huudle-character-spacing-0);
  text-decoration: var(--huudle-decoration-underline);
}

.huudle-typography-12-14-semibold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-semibold);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-14);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-14-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-14);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-14-bold-normal-0-2 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-14);
  letter-spacing: var(--huudle-character-spacing-0-2);
}

.huudle-typography-12-15-normal-normal-0-15 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-15);
  letter-spacing: var(--huudle-character-spacing-0-15);
}

.huudle-typography-12-16 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-16);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-16-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-16);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-16-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-16);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-18-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-18);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-18-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-18);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-20 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-20-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-20-medium-lowercase {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
  text-transform: var(--huudle-text-transform-lowercase);
}

.huudle-typography-12-20-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-24-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-24);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-12-30-bold-normal-0-15 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-12);
  line-height: var(--huudle-line-spacing-30);
  letter-spacing: var(--huudle-character-spacing-0-15);
}

.huudle-typography-14-17 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-14);
  line-height: var(--huudle-line-spacing-17);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-14-17-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-14);
  line-height: var(--huudle-line-spacing-17);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-14-17-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-14);
  line-height: var(--huudle-line-spacing-17);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-14-17-bold-underline {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-14);
  line-height: var(--huudle-line-spacing-17);
  letter-spacing: var(--huudle-character-spacing-0);
  text-decoration: var(--huudle-decoration-underline);
}

.huudle-typography-14-20 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-14);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-14-20-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-14);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-14-24 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-14);
  line-height: var(--huudle-line-spacing-24);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-14-24-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-14);
  line-height: var(--huudle-line-spacing-24);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-14-24-medium-titlecase {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-14);
  line-height: var(--huudle-line-spacing-24);
  letter-spacing: var(--huudle-character-spacing-0);
  text-transform: var(--huudle-text-transform-titlecase);
}

.huudle-typography-14-30-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-14);
  line-height: var(--huudle-line-spacing-30);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-16-20 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-16);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-16-20-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-16);
  line-height: var(--huudle-line-spacing-20);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-16-22 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-16);
  line-height: var(--huudle-line-spacing-22);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-18-24-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-18);
  line-height: var(--huudle-line-spacing-24);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-20-24 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-normal);
  font-size: var(--huudle-font-size-20);
  line-height: var(--huudle-line-spacing-24);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-20-24-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-20);
  line-height: var(--huudle-line-spacing-24);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-20-30-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-20);
  line-height: var(--huudle-line-spacing-30);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-20-24-bold {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-bold);
  font-size: var(--huudle-font-size-20);
  line-height: var(--huudle-line-spacing-24);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-24-35-medium-normal-0-3 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-24);
  line-height: var(--huudle-line-spacing-35);
  letter-spacing: var(--huudle-character-spacing-0-3);
}

.huudle-typography-28-30-medium-normal-0-35 {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-28);
  line-height: var(--huudle-line-spacing-30);
  letter-spacing: var(--huudle-character-spacing-0-35);
}

.huudle-typography-36-45-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-36);
  line-height: var(--huudle-line-spacing-45);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-40-50-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-40);
  line-height: var(--huudle-line-spacing-50);
  letter-spacing: var(--huudle-character-spacing-0);
}

.huudle-typography-45-53-medium {
  font-style: var(--huudle-font-style-normal);
  font-weight: var(--huudle-font-weight-medium);
  font-size: var(--huudle-font-size-45);
  line-height: var(--huudle-line-spacing-53);
  letter-spacing: var(--huudle-character-spacing-0);
}
