*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

body {
  min-width: 320px;
  cursor: default;
  color: var(--huudle-color-2e2836);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "introjs.scss";
@import "custom-player.scss";
@import "../../node_modules/react-toastify/scss/main.scss";
@layer components {
  @import "variables.scss";
}
@import "drawing.scss";
@import "double-range-slider";

#__next {
  width: 100%;
  height: 100%;

  > main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

input[type="text"],
input[type="password"] {
  outline-color: var(--huudle-color-655dc6);
}

#__portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999 !important;

  *,
  *::before,
  *::after {
    pointer-events: auto;
  }
}

.clamp-2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hide {
  /* Safari hack */
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.8;
  z-index: 100;
}

.countdown-text {
  position: absolute;
  font-size: 16rem;
  line-height: 1;
  color: white;
  //transform: translate(0.25rem, -1rem);
}

.countdown-circle {
  position: absolute;
  color: white;
  border: 0.5rem solid white;
  border-radius: 50%;
  padding: 10rem;
}

.countdown-inner-circle {
  padding: 9rem;
}

.miniVideoPlayer video {
  margin: 0 auto;
}

// .videoPlayer {
//   width: 50%;
//   height: 50%;
//   margin: 0 auto;
// }

.btn-txt-only {
  border: none;
  background-color: inherit;
  padding: 14px 28px;
  color: #655dc6;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}

.intro-selected-item {
  box-shadow: rgba(33, 33, 33, 0.87) 0px 0px 0px 5000px !important;
  z-index: 500 !important;
}

.arrow {
  background-color: #2e2836;
  transform: rotate(45deg);
  position: absolute;
  z-index: 500 !important;
}

.bg-glass {
  backdrop-filter: blur(8.7px);
  -webkit-backdrop-filter: blur(8.7px);
}

.audio-recorder-svg-color {
  filter: invert(99%) sepia(24%) saturate(2%) hue-rotate(341deg) brightness(107%) contrast(101%);
}

.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.arrow-long-right {
  position: relative;
  background: #ffb800;
  height: 1px;
}
.arrow-long-right::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 10px;
  border-style: solid;
  border-color: transparent transparent transparent #ffb800;
}

/* drag and drop file upload css begins */
#form-file-upload {
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
/* drag and drop file upload css ends */

.fake-legend {
  height: 15px;
  width: 25%;
  border-bottom: solid 1px rgba(46, 40, 54, 0.1);
  text-align: center;
  margin: 2em 0;
}
.fake-legend span {
  background: #fff;
  position: relative;
  top: 0;
  padding: 0 20px;
  line-height: 30px;
}

.scrollbar-style::-webkit-scrollbar {
  width: 5px;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #828282;
  border-radius: 20px;
}

.scrollbar-style::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-style-small::-webkit-scrollbar {
  height: 4px;
}

.avoid-style-override {
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }
}

.mdWrapper {
  font-family: "Poppins", sans-serif;

  /* Paragraph styles */
  p {
    font-size: 14px;
    line-height: 1.5rem;
  }
  /* Heading styles */
  h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1rem;
    line-height: 1;
    // color: #ffb800;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0.75rem;
    // color: #ffb800;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    // color: #ffb800;
  }

  /* List styles */
  ul {
    list-style-type: disc;
    margin-left: 1.25rem;
    margin-bottom: 1rem;
  }

  ol {
    list-style-type: decimal;
    margin-left: 1.25rem;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
    list-style: circle;
    font-size: 1rem;
  }
}

p:empty {
  min-height: 24px;
}
