@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@400;500&display=swap");
.introjs-tooltip {
  min-width: 250px !important;
  max-width: 300px !important;
  background-color: #693ac7 !important;
  color: white;
  border-radius: 0.25rem;
  padding-bottom: 40px;
  padding-top: 10px;
}
.introjs-tooltip-header {
  display: flex !important;
  flex-direction: row !important;
  padding-right: 10px !important;
  .introjs-tooltip-title {
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
  }
  .introjs-skipbutton {
    position: relative !important;
    color: white !important;
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
  }
}

.introjs-bullets {
  position: absolute;
  bottom: 21px;
  left: 20px;
  margin: auto;
  height: fit-content;
  margin-bottom: auto;
  ul {
    li {
      margin: auto;
      a {
        background-color: #ffffff80 !important;
      }
      .active {
        background-color: #ffffff !important;
      }
    }
  }
}

.introjs-arrow.top-middle {
  border-bottom-color: #693ac7 !important;
}

.introjs-arrow.bottom {
  border-top-color: #693ac7 !important;
}

.introjs-arrow.top {
  border-bottom-color: #693ac7 !important;
}

.introjs-arrow.left {
  border-right-color: #693ac7 !important;
}

.introjs-arrow.bottom-middle {
  border-top-color: #693ac7 !important;
}

.introjs-tooltipbuttons {
  display: flex;
  justify-content: flex-end;
  height: 30px;
  gap: 5px;
  border: none !important;
  a {
    height: 20px;
    border-radius: 999px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .introjs-button {
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none !important;
  }

  .introjs-nextbutton {
    border: none;
    color: white;
    background-color: transparent !important;
    &:focus {
      background-color: transparent !important;
      box-shadow: none;
      color: white;
      border: none;
    }
    &:hover {
      background-color: transparent !important;
      box-shadow: none;
      color: white;
      border: none;
    }
    &:active {
      color: white;
      box-shadow: none;
    }
  }
  .introjs-prevbutton {
    background-color: transparent !important;
    color: white;
  }
  margin-left: 100px;
}

.introjs-tooltiptext {
  color: white !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.29 !important;
  letter-spacing: normal !important;
  padding-top: 10px !important;
  padding-bottom: 0 !important;
}

.introjs-tooltip.last {
  .introjs-prevbutton {
    display: none;
  }
}
