.tl-background {
  background-color: transparent !important;
}
.tl-canvas {
  background-color: transparent !important;
}

.tlui-style-panel {
  padding: 5px;
}

.tlui-style-panel__section__common:not(:only-child) {
  margin-bottom: 0 !important;
}

.tlui-style-panel__section {
  gap: 10px;
  flex-direction: column-reverse !important;
}

[data-id="grey"],
[data-id="light-violet"],
[data-id="blue"],
[data-id="light-blue"],
[data-id="green"],
[data-id="light-red"],
[data-id="light-green"],
[data-id="s"],
[data-id="m"],
[data-id="l"],
[data-id="xl"],
[data-id="none"],
[data-id="semi"],
[data-id="pattern"],
[data-testid="main.action-menu"],
[data-testid="style.fill.solid"],
[data-tool="rectangle"],
[data-id="orange"],
[data-id="yellow"],
[data-id="violet"],
[data-tool="arrow"],
[data-testid="tools.more"],
[title="Delete — ⌫"],
[title="Duplicate — ⌘&thinsp;D"],
[title="Duplicate — ⌘ D"],
.tlui-debug-panel,
.tlui-layout__top__left,
.tlui-navigation-zone,
.tlui-help-menu,
.tlui-slider__container,
.tlui-style-panel__section,
[data-tool="hand"],
[data-tool="text"],
[data-tool="asset"],
[data-tool="eraser"],
[data-tool="select"],
[data-tool="note"] {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: none !important;
}

.tlui-style-panel__section:first {
  background-color: red !important;
  padding: 100px;
}

.tlui-toolbar__left {
  display: flex;
}

.tlui-style-panel__section__common:not(:only-child) {
  border: none !important;
}

.tlui-toolbar__extras__controls {
  background: white !important;
  border-radius: 25% !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.tlui-toolbar__extras__controls::before {
  background: white !important;
  border-radius: var(--radius-4) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.tlui-toolbar__tools {
  background: white !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  box-shadow: none !important;
}

.tlui-toolbar__extras__controls button:nth-child(4) {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: none !important;
  background-color: red;
}

.tlui-toolbar__extras__controls button:nth-child(4)::before {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: none !important;
  background-color: red;
}

.tlui-button-grid__four {
  grid-template-columns: 40px 40px 0 0 !important;
}
