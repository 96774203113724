.react-multi-email {
  > span[data-placeholder] {
    top: 0 !important;
    color: var(--huudle-color-adadad) !important;
  }
}

.Toastify__toast-icon {
  width: 50px !important;
}

.tooltip {
  &-container {
    --tooltip-background: #000;
    --tooltip-color: #fff;
    --tooltip-z-index: 99999;

    font-style: var(--huudle-font-style-normal);
    font-weight: var(--huudle-font-weight-medium);
    font-size: var(--huudle-font-size-12);
    line-height: var(--huudle-line-spacing-16);
    letter-spacing: var(--huudle-character-spacing-0);
    display: flex;
    flex-direction: column;
    background-color: var(--tooltip-background);
    color: var(--tooltip-color);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 3px 6px;
    transition: opacity 0.3s;
    z-index: var(--tooltip-z-index);

    &[data-popper-interactive="false"] {
      pointer-events: none;
    }

    &[data-popper-placement*="top"] .tooltip-arrow {
      bottom: -12px;
      left: 0;

      &::after {
        border-color: var(--tooltip-background) transparent transparent transparent;
        border-width: 6px 6px 0 6px;
      }
    }

    &[data-popper-placement*="right"] .tooltip-arrow {
      top: 0;
      left: -6px;

      &::after {
        border-color: transparent var(--tooltip-background) transparent transparent;
        border-width: 6px 6px 6px 0;
      }
    }

    &[data-popper-placement*="bottom"] .tooltip-arrow {
      top: -6px;
      left: 0;

      &::after {
        border-color: transparent transparent var(--tooltip-background) transparent;
        border-width: 0 6px 6px 6px;
      }
    }

    &[data-popper-placement*="left"] .tooltip-arrow {
      top: 0;
      right: -12px;

      &::after {
        border-color: transparent transparent transparent var(--tooltip-background);
        border-width: 6px 0 6px 6px;
      }
    }
  }

  &-arrow {
    position: absolute;
    width: 12px;
    height: 12px;
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      margin: auto;
    }
  }
}

.react-datepicker {
  background: var(--huudle-color-f5f6f8);
  border: none;
  padding: 0px 17px;

  &__month {
    margin: 0px;
    padding: 0 0 1rem 0;
    &-text {
      &--selected,
      &--in-selecting-range,
      &--in-range {
        border-radius: 50%;
        background-color: rgba(101, 93, 198, 0.11);
        color: var(--huudle-color-000000);
      }
    }
  }

  &__quarter-text {
    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: 50%;
      background-color: rgba(101, 93, 198, 0.11);
      color: var(--huudle-color-000000);
    }
  }

  &__year-text {
    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: 50%;
      background-color: rgba(101, 93, 198, 0.11);
      color: var(--huudle-color-000000);
    }
  }

  &__day {
    &:hover {
      color: var(--huudle-color-000000);
    }
    &--selected,
    &--range-start {
      font-weight: bold;
    }

    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: 50%;
      background-color: rgba(101, 93, 198, 0.11);
      color: var(--huudle-color-000000);
    }

    &--range-end {
      color: var(--huudle-color-ffffff);
      background-color: rgba(101, 93, 198, 1);
    }

    &:hover {
      border-radius: 50%;
      background-color: rgba(101, 93, 198, 0.11);
    }

    &--keyboard-selected {
      border: none;
      border-radius: 50%;
      background-color: var(--huudle-color-655dc6) !important;
      color: white;
      font-weight: bold;
    }

    &--disabled {
      &:hover {
        background: transparent !important;
        color: #ccc !important;
        cursor: not-allowed;
      }
    }

    &-names {
      margin-bottom: 4px;
      font-weight: 500;
    }
  }

  &__header {
    background: var(--huudle-color-f5f6f8);
    border: 1px solid transparent;
    padding: 0px !important;
  }
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: rgba(101, 93, 198, 0.11);
}

.invite-modal-input {
  position: relative;
}

.invite-modal-input > span[data-placeholder="true"] {
  position: absolute;
  left: 8px !important;
  top: 8px !important;
}

.invite-modal-input-inline > div {
  display: inline-flex !important;
}

.invite-modal-input-width > input {
  width: 100px !important;
}
